import axios from 'axios'
import store from '../store'
import router from '@/router'
import dayjs from 'dayjs'
import i18n from '@/i18n'
import {useToast} from 'vue-toastification'
const toast = useToast()

import { getIsoLanguage } from '@/helpers/getIsoLanguage'

// CONSTANTS
const API_DATA_URL = process.env.VUE_APP_API_DATA_URL
const API_DATA_TIMEOUT = process.env.VUE_APP_API_DATA_TIMEOUT
// TO DELETE :: when compil is not needed anymore

const urlExceptionArray = ['/informations']
let isRenewing = false

/**
 * Create axios instance
 * Will be used through api to request services with JWT
 */
const httpClient = axios.create({
    timeout: API_DATA_TIMEOUT,
    headers: {
        'Content-Language': getIsoLanguage(),
    }
})

/**
 * Init JWT interceptor
 */
function initJWTInterceptor() {
    httpClient.defaults.baseURL = getAPIDataURL()
    httpClient.defaults.headers.post['Content-Type'] = 'multipart/form-data'
    

    /**
     * On every request made trough httpClient
     * - check that jwt is not expired
     *  - it is : renew it
     *  - it is not : next
     */
    httpClient.interceptors.request.use(async (config) => {

        // TODO : in case of new request whil renewing,
        // waiting for end of renewing before making the request
        if (store.state.auth.jwt && store.state.auth.expireAt && !urlExceptionArray.includes(config.url)) {
            let utc = require('dayjs/plugin/utc')
            dayjs.extend(utc)
            const expirationTime = dayjs.utc(store.state.auth.expireAt)
            const renewExpirationTime = dayjs.utc(store.state.auth.renewExpireAt)
            const currentTime = dayjs.utc()
            if (config.url === '/renew' || config.url.match('/logout')) {
                setupAuthorizationHeader(config, store.state.auth.jwtRenew)
            // Checking if renew token is expired
            } else if (expirationTime && expirationTime < currentTime) {

                // If renew is expired : disconnection
                if (renewExpirationTime && renewExpirationTime < currentTime) {
                    toast.info(i18n.global.t('alerts.connection.expired'), {
                        icon: 'fas fa-power-off'
                    })
                    router.push({
                        name: 'Logout',
                        query: {
                            call: 0,
                        }
                    })
                    throw new axios.Cancel('Session timed out')

                // Else : renewing token
                } else {
                    isRenewing =  true

                    // Renewing the token
                    await router.replace({
                        name: 'Renew'
                    })

                    setupAuthorizationHeader(config)

                    isRenewing =  false

                    throw new axios.Cancel('Renew')
                }
            } else {
                setupAuthorizationHeader(config)
            }
        }

        return config
    }, (error) => {
        console.log(error)
        return Promise.reject(error)
    })
}

/**
 * Manage response errors
 */
function initErrorInterceptor() {
    httpClient.interceptors.response.use((response) => {
        if (response.status === 200) {
            if (response.data.error !== 0) {
                if (response.data.message) {
                    toast.error(response.data.message)
                } else if (!i18n.global.t(`alerts.errors.${response.data.code}`).match('alerts.errors')) {
                    toast.error(i18n.global.t(`alerts.errors.${response.data.code}`))
                }
            }
            return response.data
        } else {
            if (store.state.auth.logged === true) {
                router.push({
                    name: 'Error',
                    params: {
                        code: response.status
                    }
                })
            } else {
                return false
            }
        }
    }, (error) => {
        if (error) {
            // TODO : nouvelle gestion du 401
            if ((error.response && error.response.status === 401) || error.toString() === 'Error: Network Error') {
                if(router.currentRoute.value.name !== 'Login'){
                    router.push({
                        name: 'Logout',
                        query: {
                            call: 0,
                        }
                    })
                }
                
            } else {
                throw error
            }
            return Promise.reject(error);
        }
    })
}

/**
 * Setup authorization header with given jwt
 * by default use jwt in auth store
 * @param jwt
 */
function setupAuthorizationHeader(config, jwt = store.state.auth.jwt) {
    const BEARER = `bearer ${jwt}`
    httpClient.defaults.headers.common.Authorization = BEARER
    config.headers.Authorization = BEARER
}

// we get the API Data URL
export function getAPIDataURL() { switch(window.location.hostname){ //Env DEV 
    case 'dev2.intratone.info' : 
    case 'dg.intratone.info': 
    case 'localhost': 
    case '192.168.137.1':
    case 'dev8.intratone.info': 
    //return 'https://apiintradev.intratone.info'; 
        return 'https://apiintradev3.intratone.info'; 
    case 'test8.intratone.info':
    case 'test.intratone.info': 
        return 'https://apidata-test.intratone.info'; 
    case 'test4.intratone.info':
        return 'https://apidata-test4.intratone.info'; 
    case 'cert.intratone.info':
        return 'https://apidata-cert.intratone.info';
    case 'wwv.intratone.info':
        return 'https://apidata-vgk.intratone.info';
    case 'wwc.intratone.info':
        return 'https://apidata-cert.intratone.info';
    case 'salon.intratone.info': 
        return 'https://apidata-test.intratone.info'; //Env PROD 
    case 'demo.intratone.info': 
        return 'https://apidata.intratone.info'; 
    default: 
        return 'https://apidata.intratone.info'; } }

// we get the API Kibolt 
export function getAPIKiboltURL() {
    if (window.location.hostname === 'dev2.intratone.info' 
    || window.location.hostname === 'dev8.intratone.info' 
    || window.location.hostname === 'dev1.intratone.info' 
    || window.location.hostname === 'localhost' 
    || window.location.hostname === 'dg.intratone.info'
    ) { // TODO NE PAS COMMIT
        return 'https://apiload-test.kibolt.info/';
    } else if (window.location.hostname === 'test.intratone.info' || window.location.hostname === 'test8.intratone.info') {
        return 'https://apiload-test.kibolt.info/';
    } else if (window.location.hostname === 'salon.intratone.info') {
        return 'https://apiload-test.kibolt.info/';
    } else if (window.location.hostname === 'demo.intratone.info') {
        return 'https://apiload.kibolt.info/';
    } else {
        return 'https://apiload.kibolt.info/';
    }
}

// init interceptors
initJWTInterceptor()
initErrorInterceptor()

export default httpClient
